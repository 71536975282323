















import { Component, Vue } from "vue-property-decorator";
import CitasSemanaActual from "@/store/modules/Dashboards/CitasSemanaActual-module";
@Component({
  components: {}
})
export default class CitasPendietesRealizadas extends Vue {
  public created() {
    CitasSemanaActual.getCitasSemanaActuales();
  }

  public get datos() {
    return CitasSemanaActual.CitasSemanaActuales;
  }
}
